<template>
    <div  id="loading" class="vs-con-loading__container"></div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const  { mapGetters: mapGetters, mapActions: mapActions } = createNamespacedHelpers('appSetting')
export default {
    name: "loading-page",
    props: {
        active: { type: Boolean, default: false}
    },
    computed: {
        ...mapGetters(['GET_LOADING_STATUS'])
    },
    watch: {
        active (status){
            if(!status) {
                this.closeLoading()
            } else {
                this.$vs.loading()
            }
        },
        GET_LOADING_STATUS (status){
            if(!status) {
                this.closeLoading()
            } else {
                this.$vs.loading()
            }
        }
    },
    created () {
        let status = this.GET_LOADING_STATUS
        if(!status) {
            this.$vs.loading.close()
        } else {
            this.$vs.loading()
        }
    },
    methods: {
        ...mapActions(["LOADING_STATUS"]),
        closeLoading(){
             setTimeout( ()=> {
                this.$vs.loading.close()
            }, 800);
        }
    }
}
</script>