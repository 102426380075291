<template>
    <div class="navbar-register dashbord-menu shield-zone">
        <div class="wrap-nav">
            <nav-logo></nav-logo>
            <slot name="options"></slot>
        </div>  
        <div class="nav-actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>
<script>
import navLogo from '@/components/Navs/NavLogo.vue'
export default {
    name: "navbar",
    components: {
        navLogo,
    }
}
</script>