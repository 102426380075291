<template>
    <div class="layout">
        <navbar>
            <home-menu slot="options"></home-menu>
            <nav class="navigation" slot="actions">
                <li class="navigation__item navigation__item--has-submenu">
                    <a href="#" class="navigation__link"> Dashboard </a>
                    <div class="submenu">
                        <div class="submenu__content">
                            <div class="submenu__box">
                                <ul class="submenu-navigation">
                                    <li class="submenu-navigation__item">
                                        <router-link to="/dashboard" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Invoice vs Received</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/dashboard-2" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Ventas</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/dashboard-3" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Agentes</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/dashboard-4" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Proveedores</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/dashboard-5" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Servicios</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="navigation__item navigation__item--has-submenu">
                    <a href="#" class="navigation__link"> Productividad </a>
                    <div class="submenu">
                        <div class="submenu__content">
                            <div class="submenu__box">
                                <ul class="submenu-navigation">
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/stats-locations" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Locaciones</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/stats-serv-types" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Tipos de servicios</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/stats-suppliers" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Proveedores</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/sales-achivied" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Ventas Logradas</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="navigation__item navigation__item--has-submenu">
                    <a href="#" class="navigation__link"> Ventas </a>
                    <div class="submenu">
                        <div class="submenu__content">
                            <div class="submenu__box">
                                <ul class="submenu-navigation">
                                    <li class="submenu-navigation__item">
                                        <router-link to="/" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Reservaciones</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/tariff-non-acc" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Tarifas No Acc</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/sales" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Ventas</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="navigation__item navigation__item--has-submenu">
                    <a href="#" class="navigation__link"> Contabilidad </a>
                    <div class="submenu">
                        <div class="submenu__content">
                            <div class="submenu__box">
                                <ul class="submenu-navigation">
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/payment-suppliers" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Cuentas por pagar</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/accounts-receivable" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Cuentas por cobrar</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="navigation__item navigation__item--has-submenu">
                    <a href="#" class="navigation__link"> Financiero </a>
                    <div class="submenu">
                        <div class="submenu__content">
                            <div class="submenu__box">
                                <ul class="submenu-navigation">
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/cost-and-sale-by-service" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Costo venta servicio</span>
                                        </router-link>
                                    </li>
                                    <li class="submenu-navigation__item">
                                        <router-link to="/reports/booking-financials-analysis" class="submenu-navigation__link">
                                            <span class="submenu-navigation__title">Analisis Financiero</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="navigation__item">
                    <router-link to="/login" class="navigation__link">Salir</router-link>
                </li>
            </nav>
        </navbar>
        <div class="page-view shield-zone">
            <router-view/> 
        </div>
        <loading></loading>
    </div>
</template>
<script>
import loading from '@/components/Loading.vue'
import navbar from '@/components/Navs/Navbar.vue'
import homeMenu from '@/components/Navs/HomeMenu.vue'
export default {
    name: "main-layout",
    components: {
        navbar,
        loading,
        homeMenu
    },
    data () {
        return {
            user: "",
            dashboard: ['admin@asuaire.com'],
            ventas: ['admin@asuaire.com', 'ventas@asuaire.com'],
            prod: ['admin@asuaire.com', 'prod@asuaire.com'],
            conta: ['admin@asuaire.com', 'conta@asuaire.com'],
            financiero: ['admin@asuaire.com', 'financiero@asuaire.com'],
        }
    },
    created () {
        this.user = JSON.parse(localStorage.getItem('USER'))
    }
}
</script>