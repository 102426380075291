<template>
    <div class="options">
        <!-- <router-link to="/" class="router-link">{{ $t('operations') }}</router-link>
        <router-link to="/business" class="router-link">{{ $t('contacts') }}</router-link>
        <router-link to="/business" class="router-link">{{ $t('financies') }}</router-link>
        <router-link to="/business" class="router-link">{{ $t('oferts') }}</router-link>
        <router-link to="/business" class="router-link">{{ $t('reports') }}</router-link>
        <router-link to="/business" class="router-link">{{ $t('settings') }}</router-link> -->
    </div>
</template>
<script>
export default {
    name: "HomeMenu",
}
</script>