<template>
    <div class="brand" @click="goHome">
        <img src="@/assets/images/logo.svg" alt="Logo Asuaire">
    </div>
</template>
<script>
export default {
    name: 'nav-logo',
    methods: {
        goHome() {
            this.$router.push({ name: 'RouteHome'});
        }
    }
}
</script>